@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.card {
  padding: 20px 15px 10px 15px;
  margin: 15px 5px 15px 5px;
  width: fit-content;
}

.headerTitle {
  padding-top: 10px;
  transform: translate(-5px, 0px);
}

.top_margin_10 {
  margin-top: 10px;
}

.sub_title_header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 7px !important;
  color: #272522;
}

.margin_bottom_5 {
  margin-bottom: 5px;
}

.trackIcon {
  background-color: rgb(238, 236, 249);
  color: rgb(73, 58, 177);
  width: 40px;
  border-radius: 6px;
  font-size: 24px;
  padding: 5px;
}

.negative_left_margin {
  margin-left: -21px;
}

.big_bold_black {
  font: normal normal 600 32px/48px DM Sans;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
}

.header_main_title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0px;
}

.margin_bottom_10 {
  margin-bottom: 10px;
}
.textDesign {
  color: "#D5D4DC";
  font-weight: 600;
}

.flex_4 {
  flex: 4;
}

.bottom_margin_50 {
  margin-bottom: 50;
}

.labelText {
  display: inline;
  font-size: 12;
  margin-left: 4px;
}

.font_size_14 {
  font-size: 14;
}

.left_padding_15 {
  padding-left: 15px;
}

.chart_tooltip {
  color: black;
  font-weight: 600;
  padding: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
}

.normal_normal_grey {
  font: normal normal normal 18px/24px DM Sans;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
}

.seperator {
  background: #e4e8f0 0% 0% no-repeat padding-box;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}

.overflowY_auto {
  overflow-y: auto;
}

.desktop_actions {
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  margin-left: -7px;
  margin-top: 2rem;
  padding: 0.75rem 30px;
  box-shadow: 0px 2px 4px #00000029;
  width: -webkit-fill-available;
  z-index: 10;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  opacity: 1;
  justify-content: space-between;
}

.share_modal_button {
  position: relative;
  color: #6c5dd3;
  border: 2px solid #6c5dd3;
  border-radius: 10px;
  padding: clamp(6px, 1vh, 12px);
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  width: clamp(calc(250px - 25px), calc(50vh), calc(400px - 25px));
  max-width: 400px;
  min-width: 250px;
  font-size: clamp(12px, 1.5vh, 16px);
  text-align: center;
  margin: clamp(6px, 2vh, 16px) 0;
  cursor: pointer;
  transition: 0.1s ease-in all;
}

.table_header {
  font-size: 24px;
  color: black;
  margin: 0 0 25px 0;
  font-weight: 600;
}

.share_modal_button:hover {
  background-color: #6c5dd3;
  color: white;
}

.share_modal_button_icon {
  position: absolute;
  left: 20px;
  font-size: 2.5vh !important;
}

.share_social_container {
  margin: clamp(12px, 4vh, 24px) 0 0;
  /* text-align: center; */
}

.share_social_text {
  text-transform: uppercase;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: clamp(10px, 1.3vh, 14px);
}

.share_social_icon_container {
  margin: 1.1vh 0 0;
}

.share_social_icon {
  font-size: max(120px, 14.8vh);
  width: 3.2vh;
  height: 3.2vh;
  min-width: 20px;
  min-height: 20px;

  margin: 0 8px;
  cursor: pointer;
  transition: 0.1s ease-in all;
}

.share_social_icon:hover {
  transform: scale(1.1);
}

.checklist_card {
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #e5e3dd;
  background-color: white;
  width: 100% !important;
}

.link_modal {
  width: 400px;
  max-width: 90%;
  padding: 24px 28px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  color: #2e384d;
}

.options_modal {
  background-color: #fff;
  box-shadow: 0px 2px 4px gray;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 5px 0;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  outline: none;
}

.listItem {
  width: 90vw !important;
  padding: 0 !important;
}

.grey_background {
  background: #dfe1e5 0% 0% no-repeat padding-box;
}

.dropdown {
  outline: none;
  background: #e8e8e8;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  margin: 0 0 0 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.image {
  margin: 0 0 2vw 0;
}

.textarea_custom {
  overflow: hidden;
  resize: vertical;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  min-height: 100px;
  padding: 10px;
}

.form_card {
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #e5e3dd;
  background-color: white;
  width: 100% !important;
}

.radio_option {
  margin: 5px 20px 0 0;
}

.padding_nil {
  padding: 0 !important;
  margin: 0;
}

.batch_text {
  font-size: 12px !important;
}

.down_svg {
  margin: 20px 0 0 -30px;
  position: absolute;
}

.line {
  background-color: #e5e3dd;
  height: 1px;
  width: 100%;
}

.sticky {
  padding-bottom: 20px;
  position: sticky;
  top: 0px;
  padding: 55px 0 0 0;
  z-index: 100;
  background-color: #ffffff;
}

.alert_success {
  text-align: center;
}

.green_svg {
  fill: green !important;
  font-size: 40px !important;
}

.warning_svg {
  fill: #efcc00 !important;
  font-size: 80px !important;
}

.clear_filter {
  margin: 10px 0;
  cursor: pointer;
}

.modal {
  background-color: white;
  padding: 15px 20px 15px 20px;
}

.week_modal {
  width: auto;
  white-space: nowrap;
}

.delete_batch {
  cursor: pointer;
}

.week_days {
  margin: 0 10px 0 0;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td,
.th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.grey_small_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: #666666;
}

.small_bold_grey {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
}

.full_width_input {
  display: inline-block;
  width: 100%;
}

.url {
  display: inline-block;
}

.schedule_card {
  padding: 0;
  margin: 25px 5px 15px 5px;
  border-radius: 0 !important;
  /* width: fit-content; */
  height: 260px;
  width: 234.4px;
}

.small_input {
  width: 50px;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  border: solid 1px #f2f2f4;
  background-color: #f2f2f4;
  margin: 0 5px 0 0;
  font-size: 12px;
  color: black;
}

.time_input {
  width: 50px;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  border: solid 1px #f2f2f4;
  background-color: #f2f2f4;
  margin: 0 5px 0 0;
  font-size: 12px;
  color: black;
}

.schedule_card_container {
  padding: 0px 10px 10px 0px;
}

.draft_schedule {
  width: 234.4px;
  height: 133.8px;
  background-color: #fff;
  border-radius: 4px;
  object-fit: contain;
}

.schedule_card_img {
  height: 133.8px;
  margin-bottom: 15px;
  width: 100%;
  object-fit: cover;
}

.schedule_card_title_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  padding: 0 10px;
  margin: 0px;
}

.schedule_card_title {
  font-size: 16px;
  opacity: 0.8;
  font-weight: 600;
  text-align: center;
  align-self: center;
  justify-self: center;
  color: black;
  padding: 10px 0 10px 0;
  max-width: 100%;
  overflow-wrap: break-word;
}

.schedule_card_body {
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  align-self: center;
  justify-self: center;
  color: #3b3b3b;
  margin: 5px 0 15px 0;
}

.batch_date_input {
  width: 165px !important;
  margin: 0 10px 0 0 !important;
}

.batch_delete {
  margin: 0 100px 0 0;
}

.richText {
  width: 100%;
  max-width: 750px;
  height: fit-content;
  margin-bottom: 20px;
}

.small_grey_text {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
  font-weight: 600;
}

.grey_cta {
  font-size: 16px;
  color: #666666;
  margin: 10px 15px 0 0;
  font-weight: 600;
}

.bold_black_medium_text {
  font-size: 16px;
  color: black;
  font-weight: bold;
  margin: 0 6px 0 0;
  line-height: 1.33;
  padding-left: 0px !important;
}

.quote_loader {
  position: absolute;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 300px;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.price_input {
  outline: none;
  background: #e8e8e8;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  margin: 10px 0 0 0;
  padding: 5px 5px 5px 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.time_input {
  outline: none;
  background: #e8e8e8;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  width: 150px;
  height: 30px;
}

.comm_input {
  width: 100%;
  margin: 5px 0 5px 0;
  border: none;
}

.color_grey {
  color: #666666;
}

.bold_black_normal_text {
  font-size: 16px;
  color: black;
  margin: 0 6px 0 0;
  font-weight: normal;
}

.bold_normal_text {
  font-size: 16px;
  color: black;
  margin: 0 6px 0 0;
  font-weight: 600;
}

.bold_text {
  font-size: 16px;
  color: black;
  margin: 0 6px 0 0;
  font-weight: 600;
}

.msg_container {
  border: 1px solid #666666;
  padding: 10px 15px;
  border-radius: 5px;
  overflow: hidden;
}

.editModeTextArea {
  border: 1.5px solid #493ab1;
  padding: 10px 15px;
  border-radius: 5px;
  overflow: hidden;
}

.rightShift {
  margin: 0 25px 0 0;
}

.or {
  width: fit-content;
  padding: 15px 0 15px 0;
}

.bold_black_large_text {
  font-size: 24px;
  color: black;
  margin: 0 0 16px 0;
  font-weight: 600;
}

.save_cta {
  background-color: #6c5dd3;
  padding: 7px 20px 7px 20px;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  justify-content: right;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
}

.new_save_cta {
  background-color: #6c5dd3;
  padding: 7px 12px 7px 12px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
}

.new_alt_cta {
  border: 1px solid #6c5dd3;
  background-color: #fff;
  padding: 7px 12px 7px 12px;
  border-radius: 5px;
  color: #6c5dd3;
  font-size: 14px;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  text-align: center;
}

.new_text_input {
  border: 2px solid #dfe1e5;
  border-radius: 5px;
}

.tabContainer {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  background-color: #fffffff9;
  backdrop-filter: blur(4px);
  z-index: 100;
  box-shadow: 0px 2px 2px 0px #0001;
}

.tab {
  padding: 12px 8px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: 0.2s ease-in all;
  border-radius: 2px;
  font-weight: 500;
  letter-spacing: -0.15px;
  text-align: center;
  flex: 1;
}

.tab.activeTab {
  color: #3f51b5;
  border-color: #3f51b5;
}

.slider_container {
  position: relative;
}

.slider_btn {
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #3f51b5;
  font-size: 36px !important;
}

.slider_back_btn {
  left: 0;
}

.slider_next_btn {
  right: 7px;
}

.safari_save_cta {
  height: initial !important;
  width: intrinsic !important;
  white-space: nowrap;
}

.red_cta {
  background-color: #df635d;
  padding: 7px 15px 7px 15px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  justify-content: right;
  height: fit-content;
  cursor: pointer;
}

.workshop_box {
  width: 120px !important;
  margin: 10px !important;
}

.create_cta {
  color: #6c5dd3;
  border: 1px solid #6c5dd3;
  padding: 7px 20px 7px 20px;
  border-radius: 20px;
  background-color: white;
  font-size: 16px;
  justify-content: right;
  height: fit-content;
  margin: 15px 0px 0 0px;
  cursor: pointer;
}

.card_actions {
  width: 100%;
  justify-content: center;
  margin: 10px 0 25px 0;
}

.date_time_input {
  width: 150px;
  font-size: 10px !important;
}

.preview_cta {
  color: #6c5dd3;
  margin: 10px 20px 7px 20px;
  float: right;
  cursor: pointer;
}

.options_svg {
  border-radius: 50px;
  border-color: rgb(180, 180, 180);
  border-width: 1px;
  padding: 1.5px 2.5px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  position: absolute;
  margin: 30px 0 0 15px;
  float: right;
  align-self: flex-end;
  color: white;
  cursor: pointer;
  z-index: 10;
  justify-content: center;
}

.batch {
  justify-content: space-between;
  align-items: center;
}

.slot_box {
  background-color: #f2f2f4;
  padding: 5px;
  border-radius: 4px;
  color: #212121;
  margin-top: -3px;
  height: fit-content;
  width: fit-content;
  opacity: 0.4;
  border: solid 1px #f2f2f4;
  font-size: 10px;
}

.session_box {
  background-color: #f2f2f4;
  padding: 5px;
  border-radius: 4px;
  color: #212121;
  margin: 10px 20px 0 10px;
  height: 40px;
  width: 100px;
  border: solid 1px #f2f2f4;
  font-size: 12px;
  cursor: pointer;
}

.selected {
  background-color: #1c8659;
  padding: 5px;
  border-radius: 4px;
  color: white;
  /* margin-top: -3px; */
  height: fit-content;
  width: fit-content;
}

.container {
  width: 100%;
  margin: 2% 5% 2% 0;
  background-color: white;
}

.row {
  margin: 15px 0 30px 0;
}

.flex_row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.checklist_header {
  color: black;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

.checklist_row {
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0 10px 0;
  align-items: flex-start;
}

.checklist_false {
  color: #df635d;
  margin: -3px 0 0 10px;
}

.checklist_true {
  color: #1c8659;
  margin: -3px 0 0 10px;
}

.tab_title {
  color: #666666;
  font-size: 14px;
  line-height: 18px;
  margin-right: 26px;
  cursor: pointer;
}

.selected_tab_title {
  color: #6c5dd3;
}

.add_slot_cta {
  color: #1c8659;
  cursor: pointer;
}

.delete_title {
  color: #df635d;
  cursor: pointer;
}

.hint_margin {
  margin: -10px 0 0 0;
  text-align: right;
}

.card_header {
  color: #6c5dd3;
  font-size: 18px;
  line-height: 24px;
  margin: 20px 0 -10px 0;
  font-weight: 800;
}

.dm_font {
  font-family: DM Sans;
}

.hint {
  font-size: 14px;
  color: #666666;
  width: 100%;
  line-height: 1.29;
  letter-spacing: normal;
  margin: 10px 0 0 0;
}

.label {
  color: black;
  font-size: 16px;
}

.only_mobile {
  display: none;
}

.whatsapp_check {
  margin-top: 30px;
  width: 100% !important;
}

.mobile_actions {
  display: none;
}

.guestInfoInputContainer {
  width: 100%;
  height: 2.5rem;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  outline: none;
  font-family: inherit;
  border-bottom: 0.5px solid #eaeaea;
}

.guestInfoInputContainer::-webkit-input-placeholder {
  color: #000000;
  opacity: 0.35;
  font-size: 16px;
  font-weight: 600;
}

.country_select {
  margin-right: 80px;
}

.mobile_input {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.image_label {
  width: 250px;
  margin: 0 25px 0 0;
}

.gap {
  margin: 0 10px 0 0;
}

.auto_link {
  background-color: #1c8659;
}

.link {
  color: #007bff;
  width: fit-content;
}

.copyUrl {
  width: max-content;
  display: flex;
  height: 2.5rem;
  background: white;
  border: 1px solid black;
  color: #000;
  border-radius: 24px;
  font-size: 11px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto;
  padding: 7px 20px 7px 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.radio {
  margin: 7px 0 0 0;
}

.radio_label {
  margin: 0 0 0 15px;
}

.radio_row {
  margin: 10px 0 0 0;
}

.or {
  margin: 0 0 0 100px;
}

.cover_cta {
  margin: 17px 0 0 20px;
}

.cover_img_cta {
  margin: 0 5px 10px 180px;
  align-self: flex-end;
  background-color: rgb(255 255 255 / 10%);
  position: absolute;
  color: white;
  cursor: pointer;
  padding: 5px 7px 5px 7px;
  border-radius: 5px;
}

.cover_img_cta:hover {
  background-color: rgb(255 255 255 / 20%);
}

.spacer {
  margin: 0 20px 0 0;
}

.schedule_module {
  padding: 10px 0 10px 0;
}

.weekday_text {
  min-width: 90px;
  margin-top: 3px;
}

.add_session_cta {
  border: 2px solid #6c5dd3;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  background-color: white;
  color: #6c5dd3;
}

.delete_svg {
  fill: black !important;
  font-size: 30px !important;
}

.dashboard_card_title {
  font-size: 20px;
  padding: 10px;
  font-weight: 600;
}

.white_text {
  color: white;
}

.white_text_link {
  color: white;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 300;
  padding-bottom: 15px;
}

.purple_text {
  color: #6c5dd3;
}

.banner_container {
  padding: 15px;
  border-radius: 5px !important;
}

.title_text {
  font-size: 24px;
}

.description_text {
  font-size: 18px;
}

.small_text {
  font-size: 14px;
}

.bold_text {
  font-weight: 600;
}

.white_background {
  background-color: white;
}

.dashboard_container {
  max-width: 100% !important;
}

.dashboard_wrapper_style {
  height: 100%;
}

.dashboard_list {
  border: 1px solid #e0e0e3;
  border-radius: 5px;
  max-width: calc(100%-2px);
}

.dashboard_list_item {
  padding: 10px;
  border-bottom: 1px solid #e0e0e3;
}

.dashboard_list_item:hover {
  background-color: #efefef;
}

.no_bottom_border_radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.remove_top_border_radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.shift_right {
  right: 20px;
  position: absolute;
}

.chart_action {
  border: 1px solid black;
  height: max-content;
  padding: 2px 5px;
}

.chart_action_selected {
  background-color: #6c5dd3;
  color: #fff;
}

.dashboard_svg {
  fill: #6c5dd3 !important;
  font-size: 26px !important;
  border: 2px solid #6c5dd3;
  border-radius: 50%;
  padding: 5px;
  margin: 0 3px 0 3px;
  cursor: pointer;
}

.dashboard_svg_container {
  right: 20px;
  position: absolute;
  width: max-content;
}

.payout_cover_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px 15px 0 0;
}

.banner_image {
  width: 16em;
  height: 9em;
  overflow: hidden;
}

.tipstyle {
  display: "flex";
  color: "#fff";
  background: "#000";
  align-items: "center";
  padding: "5px";
}

.boxStyle {
  width: 10px;
  height: 10px;
}

.payout_title {
  width: 60%;
}

.loader {
  position: fixed;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 400px;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.loader_conatiner {
  height: 350px;
  flex-direction: column;
}

.loader_wrapper {
  margin: auto;
  justify-content: center;
}

.offerModal img {
  width: 100%;
  max-height: 300px;
  display: block;
}

.offerCard {
  padding: 16px;
  padding-top: 5px;
  background-repeat: no-repeat;
  background-position: bottom right;
  border-radius: 12px;
  font-family: Helvetica Neue;
  font-weight: 700;
}

.offerPopUp {
  font-family: "Helvetica Neue";
  font-size: 18px;
  line-height: 25.25px;
}

.offerPopUp ul {
  list-style: "*";
  list-style-position: inside;
  padding-left: 0;
  font-weight: normal;
}

.bannerTimerText {
  padding-top: 20px;
  opacity: 1;
  animation: fade 1s linear infinite;
}

.offerBanner {
  animation: fadeIn 0.5s;
  cursor: pointer;
  margin-top: 20px;
}

.offerBanner2 {
  animation: fadeIn 0.5s;
  cursor: pointer;
}

.timerIcon {
  vertical-align: text-bottom;
}

.offerToastCard {
  background-image: url("./assets/vectors/offers/ConfettiOffers.svg");
  background-color: #00003b !important;
  font-size: 16px;
  text-align: center;
  color: white !important;
  font-family: "Helvetica Neue";
}

.offerToastCard > .closeIcon {
  float: right;
}

.offerToast {
  padding: 10px;
}

.datePicker {
  width: 510px !important;
}

.z_index_0 {
  z-index: 0;
}

@media screen and (max-width: 780px) {
  .comm_input {
    background-color: #ffffff;
  }

  .slider_next_btn {
    right: 40px;
  }

  .price_input {
    background-color: #ffffff;
  }

  .time_input {
    background-color: #ffffff;
  }

  .container {
    width: 90%;
    margin: 2% 5% 2% 5%;
  }

  .modal_save_cta {
    margin-left: 15px !important;
  }

  .create_cta {
    margin: 15px 0px 0 20px;
  }

  .form_card {
    padding: 5px 10px;
  }

  .schedule_card_container {
    margin: auto;
    padding: 0;
  }

  .sticky {
    padding: 10px 0 0 0;
    position: relative;
  }

  .mobile_input {
    flex-direction: column;
  }

  .only_desktop {
    display: none !important;
  }

  .bold_black_large_text {
    font-size: 18px;
  }

  .only_mobile {
    display: block;
  }

  .tab_title {
    font-size: 14px;
  }

  .card_header {
    font-size: 14px;
  }

  .hint {
    font-size: 12px;
  }

  .success_msg {
    margin: 10px 0 0 0;
  }

  .card {
    padding: 5px 10px;
  }

  .schedule_card {
    padding: 0;
    width: 85vw;
    margin: 20px 0 0 0;
    height: 250px;
  }

  .label {
    font-size: 14px;
  }

  .bold_black_normal_text {
    font-size: 12px;
  }

  .schedule_card_img {
    width: 100%;
  }

  .mobile_full_width {
    width: 100%;
  }

  .whatsapp_check {
    flex-direction: column;
  }

  .mobile_confirmation {
    align-self: center;
    margin-bottom: 20px;
  }

  .mobile_actions {
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 80px;
    margin-top: 2rem;
    padding: 0.75rem 16px;
    box-shadow: 0px 2px 4px #00000029;
    width: 100%;
    z-index: 10;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #eaeaea;
    border-radius: 4px;
    opacity: 1;
    justify-content: space-between;
  }

  .preview_cta {
    margin: 7px 30px 0 0;
  }

  .richText {
    margin-bottom: 40px;
  }

  .center_text {
    text-align: center;
  }

  .delete_batch {
    font-size: 10px;
  }

  .session_box {
    font-size: 9px;
    margin: 5px 10px 0 5px;
    width: 75px;
  }

  .batch_text {
    margin: 0 25px 0 0 !important;
    font-size: 10px !important;
  }

  .grey_small_text {
    font-size: 12px;
    line-height: 1;
    margin: 0 5px 0 0;
  }

  .batch_date_input {
    width: 140px !important;
  }

  .workshop_box {
    width: 40px !important;
    margin: 10px !important;
  }

  .schedule_row {
    margin: 0 15px 0 20px;
  }

  .link_modal .bold_black_large_text {
    margin: 0;
  }

  .rightShift {
    margin: 0 10px 0 0;
  }

  .batch_delete {
    margin: 0 0 0 0;
  }

  .red_cta {
    padding: 5px 10px 5px 10px;
  }

  .cover_img_cta {
    /* display: none; */
    margin: 0 0 10px 120px;
  }

  .radio {
    margin: 2px 0 0 0;
  }

  .bold_normal_text {
    font-size: 14px;
    margin: 15px 0 0 0;
  }

  .cover_cta {
    margin: 15px 0 0 20px;
  }

  .mobile_row {
    margin: 20px 0 0 0;
  }

  .image {
    width: 300px;
  }

  .down_svg {
    margin: 15px 0 0 -30px;
  }

  .form_conatiner {
    margin-bottom: 80px;
  }

  .small_text {
    font-size: 12px;
  }

  .payout_cover_image {
    margin: 0 15px 0 0;
  }

  .save_cta {
    font-size: 12px;
  }

  .chart_action {
    border: 1px solid #cbcdd2;
    font-size: 12px;
    padding: 5px 5px;
  }

  .quote_loader {
    width: 90%;
  }

  .dropdown {
    font-size: 12px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .big_bold_black {
    font: normal normal 600 20px/24px DM Sans;
  }

  .share_modal_button {
    min-width: 270px;
    width: 80%;
  }

  .table_header {
    font-size: 18px;
  }

  .mobileParent form {
    padding-left: 15px !important;
  }

  .listItem > div:nth-child(2) > div {
    border: none;
  }

  .normalText {
    font-size: 14px;
    color: #272522;
    font-weight: 300;
  }

  .save_cta {
    border-radius: 4px;
  }
  .title_text {
    font-size: 18px;
  }
  .offerToastCard {
    font-size: 12px;
  }
  .bannerTimerText {
    font-size: 15px;
  }

  .sub_title_header {
    margin: 0 !important;
  }

  .trackIcon {
    width: 30px;
    padding: 3px;
  }
}
